if (module.hot) {
  module.hot.accept();
}

( function( $ ) {

  $('#routine .ep-btn span').on('click', function(e) {
    var epNum = $(this).data('ep');
    $('#routine .episode').removeClass('active');
    $('#routine .episode[data-ep="'+epNum+'"]').addClass('active');
  })
  $('#proteines .ep-btn span').on('click', function(e) {
    var epNum = $(this).data('ep');
    $('#proteines .episode').removeClass('active');
    $('#proteines .episode[data-ep="'+epNum+'"]').addClass('active');
  })

  $('.slider-content-routine').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-episode-routine'
  });
  $('.slider-content-routine-2').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-episode-routine-2'
  });
  $('.slider-content-proteines').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-episode-proteines'
  });
  $('.slider-content-proteines-2').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-episode-proteines-2'
  });
  $('.slider-recettes').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '.arrows-slider-recettes',
    adaptiveHeight: true
  });
  $('.products-pot').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    draggable: false,
    asNavFor: '.gamme-pot .products-mobile'
  });
  $('.gamme-pot .products-mobile').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: false,
    cssEase: 'linear',
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '80px',
    asNavFor: '.products-pot',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });
  $('.products-boire').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    draggable: false,
    asNavFor: '.gamme-boire .products-mobile'
  });
  $('.gamme-boire .products-mobile').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    fade: false,
    cssEase: 'linear',
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '90px',
    asNavFor: '.products-boire',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });
  
  $('.gamme-pot .products-desktop li').on('click' , function(){
    var dataGamme = $(this).data('gamme');
    $('.gamme-pot ul li').removeClass('active');
    $(this).addClass('active');
    $('.products-pot').slick('slickGoTo', dataGamme);
  });

  $('.gamme-boire .products-desktop li').on('click' , function(){
    var dataGamme = $(this).data('gamme');
    $('.gamme-boire ul li').removeClass('active');
    $(this).addClass('active');
    $('.products-boire').slick('slickGoTo', dataGamme);
  });

  $('.smoothScroll').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 50
        }, 1000); // The number here represents the speed of the scroll in milliseconds
        return false;
      }
    }
  });

  var count = 0;
  var feed = new Instafeed({
    accessToken: 'IGQVJVd3otWmRXNUFjbGJBenRuZAWJnS2ZAoOXU2bzdXcjZAmVWZAWNHl6QWZAwRW8tRzUwLTg5dnEtZADJlU0ZA3dzNCR19MLTdjZAE4yV3ZAmSU1vbHNRQTJRRFo0WUU0ZAlE3UUtIR2g5RmxaRnl6bnZAtUl91YQZDZD',
    // filter: function(image) {
    //   return image.tags.indexOf('HiPROHigherClub') >= 0;
    // }
    limit : 16,
    resolution:'standard_resolution',
    filter: function(image) {
      if (count == 0 || count % 4 == 0 ) {
        image.customTagOpen = '<div class="row">';
        image.customTagClose = '';
      } else if ((count + 1) % 4 == 0) {
        image.customTagOpen = '';
        image.customTagClose = '</div>';
      } else {
        image.customTagOpen = '';
        image.customTagClose = '';
      }
      count += 1;
      return true;
    },
    template: '{{customTagOpen}}<a href="{{link}}" style="background-image: url({{image}})" target="_blank"></a>{{customTagClose}}',
    after: function() {
      console.log('success');
      $('#instafeed').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        appendArrows: '.arrows-slider-feed'
      });
    }
  });
  feed.run();

  $('#burger').on('click', function(){
    $(this).toggleClass('is-active');
    $('.menu-mobile').slideToggle();
  });

} )( jQuery );

// const container = document.querySelector('#scroll-container');
// const ps = new PerfectScrollbar(container);